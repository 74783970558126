export default function () {
  const baseUrl = process.env.BASE_URL || 'https://app.juristat.com'

  return {
    accountsApiId: process.env.ACCOUNTS_API_ID,

    accountsApiKey: process.env.ACCOUNTS_API_KEY,

    accountsUrl: process.env.ACCOUNTS_SERVICE_URL || 'https://accounts-api.juristat.com',

    appApiAwsRegion: process.env.APP_API_AWS_REGION || 'us-west-2',

    appApiCommonDbDatabase: process.env.COMMON_DATABASE_NAME || '',

    appApiCommonDbHost: process.env.COMMON_DATABASE_HOST || '',

    appApiCommonDbPort: Number(process.env.COMMON_DATABASE_PORT || '5432'),

    appApiCommonDbPassword: process.env.COMMON_DATABASE_PASSWORD,

    appApiCommonDbUsername: process.env.COMMON_DATABASE_USERNAME || '',

    appApiPort: Number(process.env.APP_API_PORT) || 80,

    appApiUrl: process.env.APP_API_URL || 'https://app-api.juristat.com',

    appUrl: process.env.APP_URL || `${baseUrl}/search`,

    auth0Audience: process.env.AUTH0_AUDIENCE || 'https://juristat.com/',

    auth0ClientId: process.env.AUTH0_CLIENT_ID || '34tatDJn1Y376cAGv9WlTKusgq1wAemL',

    auth0Domain: process.env.AUTH0_DOMAIN || 'login.juristat.com',

    auth0Host: process.env.AUTH0_HOST || 'https://login.juristat.com',

    auth0Issuer: process.env.AUTH0_ISSUER
      ? process.env.AUTH0_ISSUER.split(/\s+/)
      : ['https://login.juristat.com/', 'https://juristat.auth0.com/'],

    auth0JuristatAccountsConnection:
      process.env.AUTH0_JURISTAT_ACCOUNTS_CONNECTION || 'accounts-db-username-prod',

    auth0Namespace: process.env.AUTH0_NAMESPACE || 'https://juristat.com/',

    auth0ProviderName: process.env.AUTH0_PROVIDER_NAME || 'login.juristat.com',

    authServiceUrl: process.env.AUTH_SERVICE_URL || 'https://auth-api.juristat.com',

    baseUrl,

    credentialsApiUrl: process.env.CREDENTIALS_API_URL || '',

    emptySearchUid: 'eyJ0eXBlIjoiQXBwbGljYXRpb25TZXQiLCJpbmZvIjp7ImFyZ3MiOnt9fX0=',

    hexWorkspaceToken: process.env.HEX_WORKSPACE_TOKEN || '',

    intercomAccessToken: process.env.INTERCOM_ACCESS_TOKEN,

    jiraApiToken: process.env.ATLASSIAN_PASSWORD || '',

    jiraAssigneeId: process.env.ATLASSIAN_ASSIGNEE || '557058:774d2db6-fc37-4999-8333-4a17dbe5e9ad',

    jiraAuthEmail: process.env.ATLASSIAN_AUTH_EMAIL || 'michelle.evans@juristat.com',

    jiraEmail: process.env.ATLASSIAN_EMAIL || 'sarah.garber@juristat.com',

    jiraHost: process.env.ATLASSIAN_HOST || 'https://juristatv2.atlassian.net/',

    jurilog: {
      errorSnsArn: process.env.ERRORS_SNS_TOPIC_ARN || '',
      eventSnsArn: process.env.EVENTS_SNS_TOPIC_ARN || '',
      production: true,
    },

    legacyAppUrl: process.env.LEGACY_APP_URL || '//app2.juristat.com',

    ppairUrl: process.env.PPAIR_SERVICE_URL || 'https://api.ppv2.juristat.com',

    production: true,

    appApiS3Bucket: process.env.APP_API_S3_BUCKET || '',

    sendGridApiKey: process.env.SENDGRID_TOKEN || '',

    smartshellUrl: 'https://juristat-trials.turbopatent.us/officeaction/shell',

    supportEmail: 'noreply@juristat.com',

    slackBotToken: process.env.SLACK_BOT_TOKEN || '',

    slackChannels: {
      analystRoom: 'CG34A0LSY',
      idsNotificationRoom: 'G0187FGPQ05',
      oarPriorityReportRoom: 'CMF7PAJU8',
      oarsRoom: 'CMY7GQNHW',
    },

    netDocs: {
      bakerHostetler: {
        groupId: process.env.ND_BH_GROUP_ID || '',
        groupName: process.env.ND_BH_GROUP_NAME || '',
        authUrl: process.env.ND_BH_AUTH_URL || '',
        apiUrl: process.env.ND_BH_API_URL || '',
        clientId: process.env.ND_BH_CLIENT_ID || '',
        clientSecret: process.env.ND_BH_CLIENT_SECRET || '',
      },
    },
    iManage: JSON.parse(process.env.IMANAGE_CONFIG || '{}'),
    usptoData: {
      consoleUrl: process.env.CONSOLE_API_URL || 'https://api.public.juristat.com/v2/graphql/api',
      graphqlUrl: process.env.GRAPHQL_API_URL || 'https://api.public.juristat.com/v2/graphql/app',
      legacyUrl: process.env.LEGACY_API_URL || 'https://api.juristat.com',
      s3PublicApplicationRole: process.env.S3_USPTO_PUB_APPLICATION_ROLE || '',
      db: {
        host: process.env.USPTO_DB_HOST || '',
        port: process.env.USPTO_DB_PORT || '',
        name: process.env.USPTO_DB_NAME || '',
        username: process.env.USPTO_DB_USERNAME || '',
        password: process.env.USPTO_DB_PASSWORD || '',
      },
    },

    packetKmsKeyId: process.env.PACKET_UPLOADER_KMS_KEY || '',
    packetUploaderRole: process.env.PACKET_UPLOADER_ROLE || '',

    websocketUrl: process.env.WEBSOCKET_URL || '',
  }
}
